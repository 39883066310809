<!-- 校内课程 -->
<style lang="scss" scoped>
.public-class {
    @include innerPage;
    @include pageHead(-10px);
    @include pageFoot;

    .page-head {
        .head-wrapper.column {
            margin-top: -18px;
        }
        height: 150px !important;
    }

    &-list {
        width: 100%;
        height: calc(100% - 120px);
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 42px 0 20px;

        &.no-data {
            border-radius: 10px;
            background: #fff;
            height: calc(100% - 296px);
            margin: 30px 0;
        }
        .day{
            line-height: 30px;
            font-size: 20px;
            margin-bottom: 20px;
            padding: 0 20px;
        }
        .list-inner {
            width: calc(100% + 20px);
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            &::after {
                content: '';
                width: 476px;
            }

            .item {
                width: 476px;
                border-radius: 10px;
                background: #fff;
                margin-bottom: 20px;
                overflow: hidden;
                float: left;
                margin-right: 20px;
                .title {
                    height: 60px;
                    box-sizing: border-box;
                    padding:0 20px;
                    padding-right: 20px;
                    background: #f6f7fc;
                    position: relative;
                    @include flexBox;
                    h4 {
                        flex: 1;
                    }

                    .el-tag {
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                    .type{
                        padding: 0 10px;
                        height: 20px;
                        font-size: 12px;
                        background: #F0FFF7;
                        color: #1DBB62;
                        border: 1px solid #1DBB62;
                        border-radius: 2px;
                        margin-left: 10px;
                        &.type1{
                            background: #F0FFF7;
                            color: #1DBB62;
                            border: 1px solid #1DBB62;
                        }
                        &.type2{
                            background: #F2F4F3;
                            color: #888888;
                            border: 1px solid #DADADA;
                        }
                        &.type3{
                            background: #F2F4F3;
                            color: #AAAAAA;
                            border: 1px solid #DADADA;
                        }
                    }
                }
                .content{
                    padding: 20px;
                }
                .teacherInfo{
                    font-size: 16px;
                    .name{
                    }
                    .date{
                        margin-left: 20px;
                    }
                }
                .datum{
                    margin-top: 20px;
                    .assist{
                        text-align: center;
                        width: 30%;
						color: #FFFFFF;
                        height: 60px;
                        padding: 10px 0;
                        background-color: #6C4ECB;
                        border-radius: 10px;
                        &:hover{
                            opacity: 0.8;
                            cursor: pointer;
                        }
                    }
                    .iconimg{
                        margin-bottom: 6px;
                        font-size: 30px;
                        &.type1{
                            background: linear-gradient(181deg, #FFBD52 0%, #FFAB22 100%);
                            -webkit-background-clip: text;		
                            -webkit-text-fill-color: transparent;
                            box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(255,159,4,0.14);
                        }
                        &.type2{
                            background: linear-gradient(181deg, #66B4F4 0%, #74BDFF 100%);
                            -webkit-background-clip: text;		
                            -webkit-text-fill-color: transparent;
                            box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(1,161,253,0.14);
                        }
                        &.type3{
                            background: linear-gradient(181deg, #63D49A 0%, #6AE2A2 100%);
                            -webkit-background-clip: text;		
                            -webkit-text-fill-color: transparent;
                            box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(99,212,154,0.2);
                        }
                    }
                }
                .schedule{
                    margin-top: 20px;
                    .status{
                        width: 30%;
                        position: relative;
                        padding: 10px 15px;
                        box-sizing: border-box;
                        cursor: pointer;
                        height: 61px;
                        .img{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            z-index: 0;
                        }
                        .text{
                            position: relative;
                            z-index: 1;
                            .h6{
                                color: #FDFDFD;
                                font-size: 12px;
                                line-height: 20px;
                            }
                            .h4{
								color: #FFFEFF;
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
                    }   
                }
                
            }
        }
    }
}
.search-ipt{
    height: 40px !important;
}
.grade-select{
    min-width:160px !important;
    padding: 0 20px;
    line-height: 40px;
    border-right: 1px solid #EEEEEE;
    height: 40px;
}
::v-deep .el-input__prefix{
    left: 5px !important;
}
.single-search{
    ::v-deep .el-input__inner{
        line-height: 40px !important;
        height: 40px !important;
        padding: 0 10px 0 30px !important;
        min-width: 145px;
        font-size: 14px !important;
    }
}
::v-deep .el-dialog__header{
    margin-bottom: 20px;
}
::v-deep .el-dialog__footer{
    border-top: none;
}
.public-class-list{
    padding: 20px 0 !important;
}
.public-class-list.no-data{
    margin:  0 !important;
}
@media screen and (max-width: 1200px) {
  .bitmap {
    width: 140px !important;
  }
  .public-class-list .list-inner{
    width: 102%;
    .item{
        width: 48%;
        margin-right: 2%;
        .datum .assist{
            height: 56px;
            padding: 5px 0;
        }
        .img{
            height: 100%;
        }
    }
  }
}
</style>

<template>
    <section class="public-class">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper column">
                <div class="wrap-plate">
                    <div class="single-search">
                        <div class="search-ipt">
                            <div class="grade-select flex_bet_align">
                                <el-date-picker v-model="startData" prefix-icon=" " value-format="timestamp" type="date" placeholder="筛选日期" @change="changeDate"> </el-date-picker>
                            </div>
                            <div class="grade-select flex_bet_align" @click="showScreen=true">
                                {{sysub_id.length>0?'已选'+sysub_id.length+'科':"选择科目"}}
                                <i class="el-icon-caret-bottom"></i>
                            </div>
                            <el-input v-model.trim="query_word" maxlength="100" placeholder="搜索课程名称" clearable
                                @clear="searchData">
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </div>
                        <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
                        <el-button size="medium" @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="public-class-list" :class="{ 'no-data': !list.length }"  v-loading="loading">
            <ul class="list-inner" v-if="!$isEmpty(list)">
                <div class="day">星期{{ startData | formatTime("d") }} {{ startData | formatTime("YYYY.MM.DD") }}</div>
                <li class="item" v-for="item in list" :key="item.teope_id">
                    <div class="title">
                        <el-tag>{{ item.sysub_name || '-' }}</el-tag>
                        <h4 class="bold line-text--1st flex_1" :title="item.sccou_cha_title">{{ item.sccou_cha_title || '-' }}</h4>
                        <div class="type type2 flex_content_center" v-if="item.is_lessons==1">未开始</div>
                        <div class="type type1 flex_content_center" v-if="item.is_lessons==2">进行中</div>
                        <div class="type type3 flex_content_center" v-if="item.is_lessons==3">已结束</div>
                    </div>
                    <div class="content">
                        <div class="teacherInfo flex_align ">
                            <div class="name">授课老师: {{item.teuse_name}}</div>
                            <div class="date">{{item.sccla_sch_tim_starttime}}-{{item.sccla_sch_tim_endtime}}</div>
                        </div>
                        <div class="datum flex_bet_align">
                            <div class="assist" @click="$router.push({name: 'SCHOOL_PLAY', params: { id: item.tecla_id}})">
                                <div class="iconimg type1 iconfont">&#xe6aa;</div>
                                课堂回放
                            </div>
                            <div class="assist" @click="$router.push({name: 'KEY_POINT', params: { id: item.tecla_id}})">
                                <div class="iconimg type2 iconfont">&#xe6d8;</div>
                                知识点
                            </div>
                            <div class="assist" @click="goChat(item)">
                                <div class="iconimg type3 iconfont">&#xe6d6;</div>
                                联系老师
                            </div>
                        </div>
                        <div class="schedule flex_bet_align">
                            <div class="status" @click="(item.practioce_status!=0&&item.is_lessons==3)?$router.push({name: 'INTERACTION', params: { id: item.tecla_id}}):''">
                                <img class="img" v-if="item.practioce_status==0" src="@assets/images/kcbg0.png" alt="">
                                <img class="img" v-else src="@assets/images/kcbg1.png" alt="">
                                <div class="text">
                                    <div class="h4" :class="item.practioce_status==0?'c-8':''">{{item.practioce_status==0?'无':'课堂'}}</div>
                                    <div class="h6" :class="item.practioce_status==0?'c-8':''">课堂互动</div>
                                 </div>
                            </div>
                            <div class="status" @click="item.sthom_school_wor_id!=''?gojobDetails(item.school_homework_status,item.sthom_school_wor_id,2):''">
                                <img class="img" v-if="item.sthom_school_wor_id==''" src="@assets/images/kcbg0.png" alt="">
                                <img class="img" v-else src="@assets/images/kcbg2.png" alt="">
                                <div class="text">
                                    <div class="h4 c-8" v-if="item.sthom_school_wor_id==''">无</div>
                                    <div class="h6 c-8" v-if="item.sthom_school_wor_id==''">校内作业</div>
                                    <div v-else>
                                        <div class="h4">已做</div>
                                        <div class="h6">校内作业</div>
                                    </div>
                                </div>
                            </div>
                            <div class="status" @click="item.homework_status!=0&&item.sthom_wor_id!=''?gojobDetails(item.homework_status,item.sthom_wor_id,1):''">
                                <img class="img" v-if="item.homework_status==0||item.sthom_wor_id==''" src="@assets/images/kcbg0.png" alt="">
                                <img class="img" v-else src="@assets/images/kcbg2.png" alt="">
                                <div class="text">
                                    <div class="h4 c-8" v-if="item.homework_status==0||item.sthom_wor_id==''">无</div>
                                    <div class="h4" v-if="item.homework_status==1&&item.sthom_wor_id!=''">已完成</div>
                                    <div class="h4" v-if="item.homework_status==2&&item.sthom_wor_id!=''">未完成</div>
                                    <div class="h4" v-if="item.homework_status==3&&item.sthom_wor_id!=''">已批阅</div>
                                    <div class="h6" :class="item.homework_status==0||item.sthom_wor_id==''?'c-8':''">家庭作业</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="no-data--empty" v-if="$isEmpty(list)">
                <img src="@assets/images/no-data3.png" alt="">
                <p>暂无课程数据哦~</p>
            </div>
        </div>
        <el-dialog title="选择科目" :visible.sync="showScreen" width="30%">
            <el-select v-model="sysub_id" multiple filterable placeholder="选择科目">
                <el-option v-for="item in subjectArray" :key="item.sysub_id" :label="item.sysub_name" :value="item.sysub_id"></el-option>
            </el-select>
            <span slot="footer">
                <el-button size="medium" @click="showScreen = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="showScreen = false">确 定</el-button>
            </span>
        </el-dialog>

    </section>
</template>

<script>
import { classschedule_list } from "@api/home"
import { $getSubject } from "@api/common"
export default {
    name: 'schoolCourse',
    data() {
        return {
            //科目信息
            subjectArray:[],
            // 筛选日期
            startData:"",
            //选中的id列表
            sysub_id:[],
            list: [], // 列表数据
            query_word: '',
            //筛选窗口弹出
            showScreen:false,
            loading:false,
        }
    },
    created() {
        this.getSubject();
        this.getData();
        this.startData=new Date().getTime()
    },
    methods: {
        //获取科目
        async getSubject(){
            let { data} = await  $getSubject();
            this.subjectArray=data
        },
        /** 获取公开课列表数据 */
        async getData() {
            this.loading=true
            let params = {
                query_word: this.query_word,
                sysub_id:this.sysub_id.toString(),
                startData:this.startData>0?parseInt(this.startData/1000):"",
            }
            let { data } = await classschedule_list(params);
            this.loading=false
            this.list = data;
            this.$forceUpdate();
        },
        //切换时间
        changeDate(e){
            if(e==null){
                setTimeout(() => {
                    this.startData=new Date().getTime()
                    this.searchData()
                }, 500);
            }else{
                this.searchData()
            }
        },
        /** 搜索 */
        searchData() {
            this.list = [];
            this.getData();
        },
        // 重置
        reset(){
            this.list = [];
            this.sysub_id = [];
            this.query_word=""
            this.startData=new Date().getTime()
            this.getData();
        },
        //跳转课堂作业
        gojobDetails(homework_status,sthom_wor_id,type){
            if(type==2){
                  //未批阅
                  if(homework_status==2){
                    this.$router.push({
                        name:"COURSE_DETAILS_NOT",
                        params: {
                        id:sthom_wor_id,
                        type:type
                        }
                    })
                }else{
                    this.$router.push({
                        name:"COURSE_DETAILS",
                        params: {
                            id:sthom_wor_id,
                            school:0,
                            accomplish:0
                        }
                    })
                }
            }else{
                //未完成
                if(homework_status==2){
                    this.$router.push({
                        name:"COURSE_DETAILS_NOT",
                        params: {
                        id:sthom_wor_id,
                        type:type
                        }
                    })
                }else{
                    this.$router.push({
                        name:"COURSE_DETAILS",
                        params: {
                        id:sthom_wor_id,
                        school:1,
                        accomplish:1
                        }
                    })
                }
            }
        },
        // 跳转聊天
        goChat(item){
            let userProfile={
                userID: item.tencent_im_account,
                avatar: item.teuse_image,
                nick: item.teuse_name,
            }
            this.$router.push({name:'INTERACTION_MESSAGE',params: { userProfile:userProfile}})
        },
    },
}
</script>